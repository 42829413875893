
$moduleNameSpace: dynamic-name-space;
[data-sparta-container] {
  .masked-element {

        .masking-value {
      margin-right: 4px;
    }

    a {
      text-decoration: none;
      color: inherit;

      .masking-action {
        color: #0053c2; 
      }

      &:focus,
      &:hover {
        .masking-action {
          text-decoration: underline;
        }
      }
    }
  }

  .spa-btn--link {

    .masking-value {
      color: #000000;
    }
  }

  .spa-input-mask-group {
    position: relative;
    .spa-input-mask-overlay {
      position: absolute;
      display: flex;
      align-items: center;
      top: 2px;
      left: 2px;
      background: #ffffff;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      padding: 12px;
      line-height: 1.25;
    }
  }
}
